import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserInformationService } from '@core/services/user-information.service';
import { ConfigAssetLoaderService } from '@core/services/config-asset-loader.service';

@Injectable()
export class HttpHeaderdatenInterceptor implements HttpInterceptor {
    benutzerInfo$ = this.userService.benutzerInfo$;

    constructor(
        private authService: OAuthService,
        private userService: UserInformationService,
        private configService: ConfigAssetLoaderService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const personenUiApiUrl = this.configService.getConfig().k5Personen.personenUiApiUrl;
        const personenApiUrl = this.configService.getConfig().k5Personen.personenApiUrl;
        // Prüfen, ob es sich um einen Aufruf des Personenservice handelt
        if (req.url.startsWith(personenUiApiUrl) || req.url.startsWith(personenApiUrl)) {
            // Headerdaten zum Http-Request hinzufügen
            req = this.addPersonenHeaderDaten(req);
        }

        // Ausführen des Requests
        return next.handle(req);
    }

    /**
     * Http-Request um Headerdaten für Personenservice erweitern
     * @param req: HttpRequest
     */
    private addPersonenHeaderDaten(req: HttpRequest<any>) {
        if (this.authService.hasValidAccessToken()) {
            this.benutzerInfo$.subscribe((benutzer) => {
                req = req.clone({
                    setHeaders: {
                        'k5-Version': 'latest',
                        'k5-Benutzername': this.normalizeString(benutzer.vorname + ' ' + benutzer.familienname),
                        'k5-Benutzerkennung': benutzer.email,
                        'k5-Anwendungsname': 'Personenverwaltung',
                        'k5-Anwendungsversion': '1',
                        'k5-Api-Key': '03f36f69-56d6-4037-aead-58f312e3d6c0'
                    }
                });
            });
        }
        return req;
    }

    /**
     * Normalisiert den String, um Sonderzeichen zu entfernen.
     * @param str String zu normalisieren.
     * @returns Normalisierter String.
     */
    normalizeString(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
}
