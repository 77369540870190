import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UnsavedChangesDialogComponent } from '@core/components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { PathConstants, RouterLinkConstants } from '@core/constants/url-constants';
import { AuthService } from '@core/services/auth.service';
import { GlobalEditService } from '@core/services/global-edit.service';
import { NavigationService } from '@core/services/navigation.service';
import { ThemeService } from '@core/services/theme.service';
import { UserInformationService } from '@core/services/user-information.service';
import { SignalRService } from '@shared/services/signal-r.service';
import { filter, Observable, Subscription, switchMap } from 'rxjs';

import { chevronAnimation } from './app.component.animations';
import { UserService } from './administration/user/services/user.service';
import { MandantClient } from '@shared/models/mandantenClient';

/**
 * Wurzelkomponente der Anwendung
 */
@Component({
    selector: 'k5-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: chevronAnimation
})
export class AppComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    chevronState: 'initial' | 'final' = 'initial';

    /**
     * Wird benötigt um die Klasse des Material themes für die Overlays zu setzen
     */
    lastMaterialTheme = '';

    // Benutzereinstellungen geladen
    userInformationLoaded$ = this.userInformationService.userInformationLoaded$;
    // Anzeige einer Fehlermeldung beim Laden der Benutzereinstellungen
    errorLoadingUserInformation: boolean = false;
    errorMessage: string = '';

    /**
     * ThemeService wird hier benötigt, da so bereits beim Anwendungsstart die Farbe gesetzt wird und
     * der Service von der DI initialisiert wird.
     */
    constructor(
        public navigationService: NavigationService,
        private userInformationService: UserInformationService,
        private userService: UserService,

        public themeService: ThemeService,
        private overlayContainer: OverlayContainer,
        public authService: AuthService,
        private router: Router,
        private dialog: MatDialog,
        private globalEditService: GlobalEditService,
        private signalRService: SignalRService
    ) {}

    /**
     * Returniert das Logout-Observable
     */
    get loggingOut$(): Observable<boolean> {
        return this.authService.loggingOut$;
    }

    /**
     * Returniert das Login-Fehler-Observable
     */
    get hasAuthenicationFailed$(): Observable<boolean> {
        return this.authService.hasAuthenticationFailed$;
    }

    ngOnInit(): void {
        this.subscription.add(
            this.navigationService.sidenavExpandable$.subscribe((expandable: boolean) => {
                this.chevronState = expandable ? 'final' : 'initial';
            })
        );

        // Update Material Theme nachdem die UI Daten geladen oder verändert wurden
        this.subscription.add(
            this.userInformationService.uiInitState$
                .pipe(
                    filter((state) => state !== null),
                    switchMap((): Observable<MandantClient.UIBenutzerEinstellungen> => {
                        // Frag danach die Benutzereinstellungen ab, da die Benutzereinstellungen vom init Objekt nicht immer akutell sind
                        return this.userService.getUserSettings(this.userInformationService.getBenutzerId());
                    })
                )
                .subscribe({
                    next: (data: MandantClient.UIBenutzerEinstellungen) => {
                        this.themeService.setTheme(
                            this.themeService.findThemeByLabelAndDarkModeFlag(data.data.farbschema, data.data.darkMode)
                        );
                    }
                })
        );

        /**
         * Update Material theme for overlays
         */
        this.subscription.add(
            this.themeService.theme$.subscribe((data) => {
                if (this.lastMaterialTheme) {
                    this.overlayContainer.getContainerElement().classList.remove(this.lastMaterialTheme);
                }
                this.overlayContainer.getContainerElement().classList.add(data.materialClassName);
                this.lastMaterialTheme = data.materialClassName;
            })
        );

        /**
         * Überprüft ob der Benutzer eingeloggt ist um die Benutzereinstellungen zu laden
         */
        this.authService.isAuthenticated$.subscribe((authenticated: boolean) => {
            if (authenticated) {
                // Löschen des Session-Storage-Eintrags für die Wiederholung der Anmeldung
                sessionStorage.removeItem('retry_login');
                this.loadUserInformation();
                // Eine Verbindung zum SignalR-Service benötigt das AccessToken daher Anmeldung abwarten
                this.signalRService.startConnection();
            }
        });
    }

    /**
     * Manuelles Laden der Benutzerinformationen
     */
    loadUserInformation(): void {
        this.subscription.add(
            this.userInformationService.refreshUserAndUiInformation().subscribe({
                next: () => {
                    const currRoute = this.router.url;
                    this.errorLoadingUserInformation = false;

                    if (currRoute.includes(`${PathConstants.INITIALIZATION}`)) {
                        this.router.navigate([RouterLinkConstants.HOME]);
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.errorLoadingUserInformation = true;
                    if (!error?.error?.detail && error?.status === 404) {
                        this.errorMessage = 'Die angeforderte Ressource wurde nicht gefunden.';
                    }
                    if (error?.error?.detail) {
                        this.errorMessage = error?.error?.detail;
                    }
                }
            })
        );
    }

    /**
     * Erneutes ausführen von fehlerhaft beendeten Initialisierungsroutinen
     */
    retry(): void {
        this.loadUserInformation();
    }

    /**
     * Erneutes ausführen der Login-Sequenz
     */
    retryLogin(): void {
        this.authService.runInitialLoginSequence();
    }

    /**
     * Logout
     */
    logout(): void {
        if (
            this.globalEditService.isEditing &&
            (this.globalEditService.getEditSectionForm() ? this.globalEditService.isFormDirty() : true)
        ) {
            const dialogRef = this.dialog.open(UnsavedChangesDialogComponent);
            dialogRef.componentInstance.leaveSiteObservable.subscribe({
                next: (leaveSite: boolean) => {
                    if (leaveSite) {
                        this.globalEditService.switchToReadMode();
                        this.authService.logout();
                    }
                }
            });
        } else {
            if (this.globalEditService.isEditing) {
                this.globalEditService.switchToReadMode();
            }
            this.authService.logout();
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.signalRService.closeHubConnection();
    }

    /**
     * Prüft ob der Benutzer authentifiziert und das Benutzerprofil
     * geladen ist.
     * @returns Ist der Benutzer authentifiziert und sind die Benutzerinformationen geladen
     */
    isAuthenticatedAndUserLoaded(): boolean {
        return this.userInformationService.hasUserInfo() && this.authService.isAuthenticated();
    }

    /**
     * Fragt nach, ob der Benutzer die Seite verlassen möchte, wenn er sich aktuell im Bearbeitungsmodus befindet
     */
    @HostListener('window:beforeunload', ['$event'])
    handleClose($event) {
        if (
            this.globalEditService.isEditing &&
            (this.globalEditService.getEditSectionForm() ? this.globalEditService.isFormDirty() : true)
        ) {
            $event.returnValue = true;
        }
    }
}
